@import '~scss/variables.scss';

.dropdownWrapper {
	position: relative;
	align-items: stretch;
	display: flex;
	// Commented out table in favor of flex's stretch property. If this introduces issues in the future, revert to table and determine an alternative solution for stretching in the nav.
	// display: table;
	width: 100%;

	@include tablet-up {
		width: auto;
	}

	&.positionRight {
		.dropdownContentArea {
			left: initial;
			right: 0;
		}
	}

	&.positionStretch {
		right: 0;

		.dropdownContentArea {
			width: 100%;
			left: -0.1rem;
		}
	}
}

.dropdownContentArea {
	background: $color-white;
	position: absolute;
	bottom: 0;
	transform: translateY(100%);
	left: 0;
	width: 28.4rem;
	z-index: $z-index-dropdown-content;
	box-shadow: $box-shadow-large;
	border: 0.1rem solid $color-gray-3;
	border-top: 0;
}
