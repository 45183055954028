@import '~scss/variables.scss';

@include button;

//Adding local removes namespace conflict with type
.linkLocal {
	// This is causing problems in safari
	// display: table;
	* {
		pointer-events: auto;
	}
}
