@mixin type-040-desktop {
	font-size: $type-040-font-size-desktop;
	line-height: $type-040-line-height-desktop;
}

@mixin type-040-mobile {
	font-size: $type-040-font-size-mobile;
	line-height: $type-040-line-height-mobile;
}

@mixin type-040 {
	@include type-040-mobile;
	@include tablet-up {
		@include type-040-desktop;
	}
}

@mixin type-060-desktop {
	font-size: $type-060-font-size-desktop;
	line-height: $type-060-line-height-desktop;
}

@mixin type-060-mobile {
	font-size: $type-060-font-size-mobile;
	line-height: $type-060-line-height-mobile;
}

@mixin type-060 {
	@include type-060-mobile;
	@include tablet-up {
		@include type-060-desktop;
	}
}

@mixin type-080-desktop {
	font-size: $type-080-font-size-desktop;
	line-height: $type-080-line-height-desktop;
}

@mixin type-080-mobile {
	font-size: $type-080-font-size-mobile;
	line-height: $type-080-line-height-mobile;
}

@mixin type-080 {
	@include type-080-mobile;
	@include tablet-up {
		@include type-080-desktop;
	}
}

@mixin type-100-desktop {
	font-size: $type-100-font-size-desktop;
	line-height: $type-100-line-height-desktop;
}

@mixin type-100-mobile {
	font-size: $type-100-font-size-mobile;
	line-height: $type-100-line-height-mobile;
}

@mixin type-100 {
	@include type-100-mobile;
	@include tablet-up {
		@include type-100-desktop;
	}
}

@mixin type-200-desktop {
	font-size: $type-200-font-size-desktop;
	line-height: $type-200-line-height-desktop;
}

@mixin type-200-mobile {
	font-size: $type-200-font-size-mobile;
	line-height: $type-200-line-height-mobile;
}

@mixin type-200 {
	@include type-200-mobile;
	@include tablet-up {
		@include type-200-desktop;
	}
}

@mixin type-300-desktop {
	font-size: $type-300-font-size-desktop;
	line-height: $type-300-line-height-desktop;
}

@mixin type-300-mobile {
	font-size: $type-300-font-size-mobile;
	line-height: $type-300-line-height-mobile;
}

@mixin type-300 {
	@include type-300-mobile;
	@include tablet-up {
		@include type-300-desktop;
	}
}

@mixin type-400-desktop {
	font-weight: $font-weight-bold;
	font-size: $type-400-font-size-desktop;
	line-height: $type-400-line-height-desktop;
}

@mixin type-400-mobile {
	font-weight: $font-weight-bold;
	font-size: $type-400-font-size-mobile;
	line-height: $type-400-line-height-mobile;
}

@mixin type-400 {
	@include type-400-mobile;
	@include tablet-up {
		@include type-400-desktop;
	}
}

@mixin type-500-desktop {
	font-weight: $font-weight-bold;
	font-size: $type-500-font-size-desktop;
	line-height: $type-500-line-height-desktop;
}

@mixin type-500-mobile {
	font-weight: $font-weight-bold;
	font-size: $type-500-font-size-mobile;
	line-height: $type-500-line-height-mobile;
}

@mixin type-500 {
	@include type-500-mobile;
	@include tablet-up {
		@include type-500-desktop;
	}
}

@mixin type-600-desktop {
	font-weight: $font-weight-bold;
	font-size: $type-600-font-size-desktop;
	line-height: $type-600-line-height-desktop;
}

@mixin type-600-mobile {
	font-weight: $font-weight-bold;
	font-size: $type-600-font-size-mobile;
	line-height: $type-600-line-height-mobile;
}

@mixin type-600 {
	@include type-600-mobile;
	@include tablet-up {
		@include type-600-desktop;
	}
}

@mixin type-700-desktop {
	font-weight: $font-weight-bold;
	font-size: $type-700-font-size-desktop;
	line-height: $type-700-line-height-desktop;
}

@mixin type-700-mobile {
	font-weight: $font-weight-bold;
	font-size: $type-700-font-size-mobile;
	line-height: $type-700-line-height-mobile;
}

@mixin type-700 {
	@include type-700-mobile;
	@include tablet-up {
		@include type-700-desktop;
	}
}

@mixin type-800-desktop {
	font-weight: $font-weight-bold;
	font-size: $type-800-font-size-desktop;
	line-height: $type-800-line-height-desktop;
}

@mixin type-800-mobile {
	font-weight: $font-weight-bold;
	font-size: $type-800-font-size-mobile;
	line-height: $type-800-line-height-mobile;
}

@mixin type-800 {
	@include type-800-mobile;
	@include tablet-up {
		@include type-800-desktop;
	}
}

@mixin type-h1-desktop {
	@include type-800-desktop;
	margin-top: 5.2rem;
	font-family: $font-family-helvetica;
	transform: translateY(-1px);
}

@mixin type-h1-mobile {
	@include type-800-mobile;
	margin-top: 5.2rem;
	font-family: $font-family-helvetica;
	transform: translateY(-1px);
}

@mixin type-h1 {
	@include type-h1-mobile;
	@include tablet-up {
		@include type-h1-desktop;
	}
}

@mixin type-h2-desktop {
	@include type-700-desktop;
	margin-top: 4.8rem;
	font-family: $font-family-helvetica;
	transform: translateY(1px);
}

@mixin type-h2-mobile {
	@include type-700-mobile;
	margin-top: 4.8rem;
	font-family: $font-family-helvetica;
	transform: translateY(1px);
}

@mixin type-h2 {
	@include type-h2-mobile;
	@include tablet-up {
		@include type-h2-desktop;
	}
}

@mixin type-h3-desktop {
	@include type-600-desktop;
	margin-top: 4rem;
	font-family: $font-family-helvetica;
}

@mixin type-h3-mobile {
	@include type-600-mobile;
	margin-top: 4rem;
	font-family: $font-family-helvetica;
}

@mixin type-h3 {
	@include type-h3-mobile;
	@include tablet-up {
		@include type-h3-desktop;
	}
}

@mixin type-h4-desktop {
	@include type-500-desktop;
	margin-top: 2.8rem;
	font-family: $font-family-helvetica;
	transform: translateY(1px);
}

@mixin type-h4-mobile {
	@include type-500-mobile;
	margin-top: 2.8rem;
	font-family: $font-family-helvetica;
	transform: translateY(1px);
}

@mixin type-h4 {
	@include type-h4-mobile;
	@include tablet-up {
		@include type-h4-desktop;
	}
}

@mixin type-h5-desktop {
	@include type-400-desktop;
	margin-top: 2.4rem;
	font-family: $font-family-helvetica;
}

@mixin type-h5-mobile {
	@include type-400-mobile;
	margin-top: 2.4rem;
	font-family: $font-family-helvetica;
}

@mixin type-h5 {
	@include type-h5-mobile;
	@include tablet-up {
		@include type-h5-desktop;
	}
}

@mixin type-h6-desktop {
	@include type-300-desktop;
	margin-top: 1.6rem;
	font-family: $font-family-helvetica;
	transform: translateY(-1px);
}

@mixin type-h6-mobile {
	@include type-300-desktop;
	margin-top: 1.6rem;
	font-family: $font-family-helvetica;
	transform: translateY(-1px);
}

@mixin type-h6 {
	@include type-h6-mobile;
	@include tablet-up {
		@include type-h6-desktop;
	}
}

@mixin type-block-title-desktop {
	margin-top: 0;
	margin-bottom: 0;
	letter-spacing: 0.1rem;
	font-weight: $font-weight-bold;
	color: $color-black;
	font-size: $type-block-title-font-size-desktop;
	line-height: $type-block-title-line-height-desktop;
	font-family: $font-family-helvetica;
}

@mixin type-block-title-mobile {
	margin-top: 0;
	margin-bottom: 0;
	letter-spacing: 0.1rem;
	font-weight: $font-weight-bold;
	color: $color-black;
	font-size: $type-block-title-font-size-mobile;
	line-height: $type-block-title-line-height-mobile;
	font-family: $font-family-helvetica;
}

@mixin type-block-title {
	@include type-block-title-mobile;
	@include tablet-up {
		@include type-block-title-desktop;
	}
}

@mixin type-body {
	font-size: 1.6rem;
	line-height: 2.4rem;
	font-family: $font-family-helvetica;
}

@mixin type-body-small {
	@include type-080;
	transform: translateY(-1px);
}

@mixin type-body-callout {
	@include type-060;
	transform: translateY(-2px);
	text-transform: uppercase;
	color: $color-gray-4;
}

@mixin link {
	color: $color-green-dark;
	font-weight: $font-weight-bold;
	text-decoration: none;
	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
}
