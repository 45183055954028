@import '~scss/variables.scss';

.bottomMenuContent {
	background-color: $color-gray-4;
	padding: $size-24;
}

.title {
	margin-top: 0;
	@include type-200;
}

.description {
	margin: $size-8 0;
	@include type-100;
}

.icon {
	margin-right: $size-4;
	position: relative;
	top: 0.6rem;
}
