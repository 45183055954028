@import '~scss/variables.scss';

.header {
	display: flex;
	position: relative;
	background-color: $color-white;
	align-items: stretch;
	height: 8rem;

	&:after {
		content: '';
		width: 100%;
		border-bottom: 0.1rem solid $color-gray-3;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
	}
}

.headerNav {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: stretch;
}

.headerNavLeft {
	padding: 2.2rem;
	width: $sidebar-width;
	border-right: 0.1rem solid $color-gray-3;
}

.logoContainer {
	display: flex;
	align-items: center;
	width: 26rem;

	@include desktop-up {
		border-right: 0.1rem solid $color-gray-3;
	}
}

.logo {
	line-height: 0;
	height: 3rem;
	@include desktop-up {
		width: 20rem;
	}
}

.userDropdown {
	display: none;
	border-left: 0.1rem solid $color-gray-3;
	min-width: 25rem;

	@include desktop-up {
		display: flex;
	}
}

.userDropdownButton {
	width: 100%;
}

.userDropdownIcon {
	margin-right: $size-16;
}

.userDropdownCaret.userDropdownCaret {
	margin-left: 2rem;
}

.userMenuButton {
	justify-content: space-around;
	padding: 0 2.2rem;
	gap: 2rem;
	font-weight: $font-weight-regular;
	text-transform: none;
}

.menuToggle {
	position: absolute;
	top: 50%;
	right: $size-container-margin-mobile;
	transform: translateY(-50%);

	padding: $size-12;
	height: $size-48;
	width: 5.8rem;
	z-index: $z-index-menu-toggle;
	margin-left: $size-2;
	cursor: pointer;
	display: block;
	border: none;
	background: none;
	margin-left: auto;
	transition: all 0.3s ease;

	@include desktop-up {
		display: none;
	}

	.target {
		height: 100%;
		position: relative;
	}

	&:hover {
		background-color: $color-gray-4;
		transition: all 0.3s ease;

		span {
			background-color: $color-gray-1;
			transition: all 0.3s ease;
		}
	}

	&:active {
		background-color: $color-gray-3;
		transition: all 0.3s ease;

		span {
			background-color: $color-gray-1;
			transition: all 0.3s ease;
		}
	}

	span {
		display: block;
		position: absolute;
		width: 100%;
		top: 50%;
		left: 0;
		transform-origin: 50% 50%;
		transition: all 0.3s ease;
		height: 0.1rem;
		border-radius: $border-radius;
		background-color: $color-gray-2;
		&:nth-child(1) {
			top: 0;
		}
		&:nth-child(3) {
			top: 100%;
		}
	}
}
