@import '~scss/variables.scss';

.alert {
	@include padding-small-vertical;
	@include padding-medium-horizontal;
	@include margin-small-vertical;
	display: flex;
	border-radius: $border-radius;
	color: $color-white;
	font-size: $size-16;

	.icon {
		margin-right: $size-8;
		flex-shrink: 0;
	}
	.dismissMessage {
		flex-shrink: 0;
		margin-top: $size-2;
		display: block;
		margin-left: auto;
	}
}

.success {
	background-color: $color-green;
}

.success-light {
	color: $color-green;
	padding: 0;
	@include tablet-up {
		padding: 0;
	}
}

.warning {
	background-color: $color-yellow;
	color: $color-gray-1;
}

.danger {
	background-color: $color-red;
}

.danger-light {
	color: $color-red;
	padding: 0;
	@include tablet-up {
		padding: 0;
	}
}
