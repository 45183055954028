.scale-fade-enter {
	opacity: 0;
	transform: scale(0.9);
}
.scale-fade-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition: all 400ms ease;
}
.scale-fade-exit {
	opacity: 1;
	transition: all 400ms ease;
}
.scale-fade-exit-active {
	opacity: 0;
	transform: scale(0.9);
	transition: all 400ms ease;
}
