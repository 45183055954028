@mixin all-button-types {
	.primary,
	.secondary,
	.danger,
	.ghost,
	.control {
		@content;
	}
}

@mixin button {
	:global {
		.user-is-tabbing {
			:local {
				@include all-button-types {
					&:focus {
						.focusRing {
							position: absolute;
							top: -0.4rem;
							right: -0.4rem;
							bottom: -0.4rem;
							left: -0.4rem;
							border: 0.2rem solid $color-green-dark;
							border-radius: 3.2rem;
							&.black {
								border-color: $color-gray-1;
							}
							&.white {
								border-color: $color-white;
							}
							&.blue {
								border-color: $color-green-dark;
							}
							&.red {
								border-color: $color-red;
							}
							&.green {
								border-color: $color-green-dark;
							}
							&.gray {
								border-color: $color-gray-4;
							}
						}
						outline: 0;
					}
				}
			}
		}
	}

	@include all-button-types {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 2.8rem;
		border: none;
		color: $color-white;
		transition: all 0.3s ease;
		font-size: 1.6rem;
		line-height: 1.6rem;
		transform: translateY(0);
		text-transform: uppercase;
		margin: 0;
		box-shadow: $box-shadow-small;
		font-weight: $font-weight-bold;

		&:hover {
			cursor: pointer;
			text-decoration: none;
		}
		* {
			pointer-events: none;
		}
		&:disabled,
		&:disabled:hover {
			cursor: initial;
		}
		&[aria-busy='true'],
		&[aria-busy='true']:hover {
			cursor: initial;
		}
		width: 100%;
		text-align: center;
		@include tablet-up {
			width: auto;
		}
	}

	.medium {
		padding: 1.75rem $size-48 1.85rem $size-48;
	}

	.small {
		padding-left: $size-12;
		padding-right: $size-12;
		padding-top: $size-12;
		padding-bottom: $size-12;
	}

	.smallest {
		padding: 0;
	}

	.primary {
		background-image: $color-gradient-yellow;
		color: $color-black;
		position: relative;
		transition: background-image 0.3s;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-image: $color-gradient-yellow;
			border-radius: 2.8rem;
			opacity: 0;
			transition: all 0.3s;
		}
		&:hover {
			&:before {
				transition: all 0.3s;
				opacity: 1;
			}
		}
		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-image: $color-gradient-yellow;
			border-radius: 2.8rem;
			opacity: 0;
			transition: all 0.3s;
		}
		&:active {
			&:after {
				transition: all 0.3s;
				opacity: 1;
				background-image: $color-gradient-dark;
			}
		}
		&:disabled,
		&:disabled:hover {
			background-image: $color-gradient-dim;
			color: $color-gray-1;
			transition: background-image 0.3s;
			&:before,
			&:after {
				opacity: 0;
			}
		}
		&[aria-busy='true'],
		&[aria-busy='true']:hover {
			background-image: $color-gradient-yellow;
			color: $color-black;
		}
	}

	.secondary {
		background-color: $color-gray-3;
		color: $color-black;
		&:hover {
			background-color: $color-gray-4;
		}
		&:active {
			background-color: $color-gray-3;
		}
		&:disabled,
		&:disabled:hover {
			background-color: $color-gray-3;
			color: $color-gray-4;
		}
		&[aria-busy='true'],
		&[aria-busy='true']:hover {
			background-color: $color-gray-3;
			color: $color-black;
		}
	}

	.danger {
		background-color: $color-red;
		&:hover {
			background-color: $color-red-dark;
		}
		&:active {
			background-color: $color-red-darker;
		}
		&:disabled,
		&:disabled:hover {
			background-color: $color-red-light;
		}
		&[aria-busy='true'],
		&[aria-busy='true']:hover {
			background-color: $color-red;
		}
	}

	.ghost {
		background-color: inherit;
		color: $color-black;
		box-shadow: none;
		border-radius: 0;
		font-weight: $font-weight-regular;
		text-transform: initial;
		width: auto;

		&:hover {
			background-color: $color-gray-4;
		}
		&:active {
			background-color: $color-gray-3;
		}
		&:disabled,
		&:disabled:hover {
			color: $color-gray-4;
		}
	}

	.sidebarLink {
		background-color: inherit;
		color: $color-black;
		box-shadow: none;
		border-radius: 0;
		font-weight: $font-weight-bold;
		color: $color-gray-1;
		text-transform: initial;
		width: auto;
		cursor: pointer;

		&:hover {
			background-color: $color-gray-3;
		}
		&:active {
			background-color: $color-gray-3;
		}
		&:disabled,
		&:disabled:hover {
			color: $color-gray-4;
		}
	}

	.control {
		background-color: transparent;
		color: $color-black;
		box-shadow: none;
		width: auto;
		border-radius: 0;
		text-transform: initial;
		font-weight: $font-weight-regular;
		position: relative;

		&.small {
			padding-bottom: 1.3rem;
		}

		&:disabled,
		&:disabled:hover {
			color: $color-gray-4;
		}
		&:hover {
			background-color: $color-gray-4;
		}
		&:active {
			background-color: $color-gray-3;
		}
	}

	.input {
		background-color: transparent;
		color: $color-black;
		box-shadow: none;
		width: auto;
		border-radius: 0;
		text-transform: initial;
		font-weight: $font-weight-regular;
		border: 0.1rem solid $color-gray-2;
		background-color: $color-gray-5;
		position: relative;

		&.small {
			padding-bottom: 1.3rem;
		}

		&:after {
			content: '';
			position: absolute;
			display: block;
			background-color: $color-yellow;
			left: 0;
			bottom: 0;
			right: 0;
			height: 0.2rem;
		}

		&:disabled,
		&:disabled:hover {
			color: $color-gray-4;
		}
		&:hover {
			background-color: $color-gray-4;
		}
		&:active {
			background-color: $color-gray-3;
		}
	}

	.link {
		padding: 0;
		@include link;
	}

	.linkSmall {
		padding: 0;
		@include link;
		font-size: 1rem;
		line-height: $size-12;
		transform: translateY(-2px);
	}

	.buttonContent {
		z-index: $z-index-button-content;
		display: flex;
		align-items: center;
		position: relative;
		pointer-events: none;
		justify-content: center;
	}
}
