@import '~scss/variables';

@include button;

.link {
	padding: 0;
	width: fit-content;
	height: fit-content;
	background-color: $color-transparent;
	color: $color-green-dark;
	text-align: left;
	transition: none;
	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
}

.spinner {
	margin-right: $size-8;
	margin-top: -0.2rem;
	margin-bottom: -0.2rem;
	.link & {
		bottom: -0.4rem;
		position: relative;
	}
	.linkSmall & {
		bottom: -0.5rem;
		position: relative;
	}
}

// Tooltip
.tooltip {
	background-color: $color-gray-1;
	color: $color-white;
	font-size: 1.2rem;
	padding: 0.4rem 1.2rem;
	border-radius: 0.3rem;
	z-index: $z-index-tooltip;
	max-width: 18rem;
	svg {
		path {
			fill: $color-green-dark;
		}
	}
}
