@import '~scss/variables.scss';

.navItem {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: $size-12;
	position: relative;
	transition: background-color 0.3s;

	&.activeItem {
		background-color: $color-gray-4;

		&:hover {
			background-color: $color-gray-3;
			text-decoration: none;
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: 0.3rem;
			background-color: $color-yellow;
		}
	}

	&:hover {
		background-color: $color-gray-3;
		text-decoration: none;
	}

	@include desktop-up {
		display: none;
		&.isVisibleOnDesktop {
			display: flex;
		}
	}
}

.icon {
	display: inline-block;
	margin-right: $size-12;
}

.title {
	@include type-100;
	color: $color-black;
}

.functionLinkContent.functionLinkContent {
	justify-content: flex-start;
}
