@import '~scss/variables.scss';

@mixin icons-to-fill {
	&.CaretDownFilled,
	&.CaretUpFilled {
		@content;
	}
}

@mixin on-button-control-hover {
	:global {
		.button:hover {
			:local {
				.control {
					@content;
				}
			}
		}
	}
}

.icon {
	@include all-svg-elements {
		stroke-width: 0;
	}
}

.gray {
	@include all-svg-elements {
		fill: $color-gray-4 !important;
	}
	@include icons-to-fill {
		@include all-svg-elements {
			fill: $color-gray-4 !important;
		}
	}
}

@include on-button-control-hover {
	&.gray {
		@include all-svg-elements {
			fill: $color-gray-3 !important;
		}
	}
}

.grayLight {
	@include all-svg-elements {
		fill: $color-gray-5 !important;
	}
	@include icons-to-fill {
		@include all-svg-elements {
			fill: $color-gray-5 !important;
		}
	}
}

@include on-button-control-hover {
	&.gray {
		@include all-svg-elements {
			fill: $color-gray-3 !important;
		}
	}
}

.white {
	@include all-svg-elements {
		fill: $color-white !important;
	}
	@include icons-to-fill {
		@include all-svg-elements {
			fill: $color-white !important;
		}
	}
}

@include on-button-control-hover {
	&.white {
		@include all-svg-elements {
			fill: $color-gray-4 !important;
		}
	}
}

.red {
	@include all-svg-elements {
		fill: $color-red !important;
	}
	@include icons-to-fill {
		@include all-svg-elements {
			fill: $color-red !important;
		}
	}
}

@include on-button-control-hover {
	&.red {
		@include all-svg-elements {
			fill: $color-red-light !important;
		}
	}
}

.green {
	@include all-svg-elements {
		fill: $color-green-dark !important;
	}
	@include icons-to-fill {
		@include all-svg-elements {
			fill: $color-green-dark !important;
		}
	}
}

@include on-button-control-hover {
	&.green {
		@include all-svg-elements {
			fill: $color-green-dark !important;
		}
	}
}

.black {
	@include all-svg-elements {
		fill: $color-gray-1 !important;
	}
	@include icons-to-fill {
		@include all-svg-elements {
			fill: $color-gray-1 !important;
		}
	}
}

@include on-button-control-hover {
	&.black {
		@include all-svg-elements {
			fill: $color-gray-1 !important;
		}
	}
}

@include on-button-control-hover {
	&.red {
		@include all-svg-elements {
			fill: $color-red-light !important;
		}
	}
}

.smallest {
	@include icon-size(1.8);
}

.small {
	@include icon-size(2);
}

.medium {
	@include icon-size(2.4);
}

.large {
	@include icon-size(4);
}

.largest {
	@include icon-size(6);
}

.giant {
	@include icon-size(10);
}

.thick {
	@include all-svg-elements {
		stroke-width: inherit;
	}
}

.skeleton {
	border-radius: 50%;
	display: inline-block;
}

.waitingComponent {
	display: inline-block;
}

.tooltip {
	background-color: $color-green;
	color: $color-white;
	padding: $size-16 2rem;
	border-radius: $border-radius;
	z-index: $z-index-tooltip;
	@include type-080;
}

.margin-left-small {
	margin-left: 0.5rem;
}

.margin-left-medium {
	margin-left: 1rem;
}

.margin-left-large {
	margin-left: 1.2rem;
}

.margin-right-small {
	margin-right: 0.5rem;
}

.margin-right-medium {
	margin-right: 1rem;
}

.margin-right-large {
	margin-right: 1.2rem;
}
