@import '~scss/variables';

// Base Style
.badge {
	border-radius: 1rem;
	background: $color-green-light;
	font-size: 0.9rem;
	font-weight: $font-weight-bold;
	min-width: 2rem;
	height: 2rem;
	text-align: center;
	color: $color-gray-1;
	padding: 1.05rem 0.5rem 0 0.5rem;
	line-height: 0;
	display: inline-block;
}
