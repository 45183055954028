@import '~scss/variables.scss';

.appFrame {
	flex: 1;
	display: flex;
	flex-direction: column;
	background-color: $color-gray-5;
	min-height: 100vh;
}

.content {
	display: flex;
	flex-direction: row;
	flex: 1;

	// This is to prevent flex issues
	overflow-y: hidden;
}

.sidebarContainer {
	display: none;
	background-color: $color-white;

	padding-top: $size-32;

	@include desktop-up {
		width: $sidebar-width;
		border-right: 0.1rem solid $color-gray-3;
		display: block;
	}

	&.isSidebarShown {
		@include desktop-down {
			display: block;
			z-index: $z-index-mobile-navigation;
			position: absolute;
			left: 0;
			top: 8.1rem;
			right: 0;
			bottom: 0;
		}
	}
}

.mainContainer {
	flex: 1;
	padding-bottom: 17rem;
}

.main {
	flex: 1;
}

.skipNavigation {
	position: absolute;
	top: 0;
	left: 0;
	z-index: $z-index-skip-navigation;
	transform: translateY(-100%);
	transition: all 0.3s ease;
	background: $color-white;
	padding: 1rem;

	&:focus,
	&:active {
		transform: translateY(0);
	}
}
