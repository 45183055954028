@import 'src/scss/mixins/browsers.scss';

// Font families
$font-family-helvetica: 'Helvetica Neue', 'Segoe UI', Tahoma, sans-serif;

// Font weights
$font-weight-light: 100;
$font-weight-regular: 400;
$font-weight-semiBold: 500;
$font-weight-bold: 700;

// Colors
$color-green-dark: #03816a;
$color-green: #04ac8c;
$color-green-light: #73cfbe;
$color-green-lighter: #cdece6;
$color-green-lightest: #edfdfa;

$color-yellow-light: #ffd400;
$color-yellow: #fdbf2d;

$color-purple: #480468;
$color-purple-light: #7a23a4;
$color-purple-lighter: #b065d4;

$color-red-light: #e18a8f;
$color-red: #ce221c;
$color-red-dark: #9c0e18;
$color-red-darker: #850b13;

$color-gradient-gray: linear-gradient(179.59deg, #27373e -0.28%, #324349 98.25%);

$color-gradient-yellow: linear-gradient(90deg, $color-yellow-light 8.81%, $color-yellow 108.57%);

$color-gradient-dim: linear-gradient(
	90deg,
	lighten(desaturate($color-yellow-light, 20%), 20%) 8.81%,
	lighten(desaturate($color-yellow, 20%), 20%) 108.57%
);

$color-gradient-dark: linear-gradient(
	90deg,
	darken($color-yellow-light, 5%) 8.81%,
	darken($color-yellow, 5%) 108.57%
);

// Neutrals
$color-black: #000000;
$color-gray-1: #5c646d;
$color-gray-2: #aab2b7;
$color-gray-3: #dedddf;
$color-gray-4: #f3f3f3;
$color-gray-5: #fafafa;
$color-white: #ffffff;

$color-transparent: transparent;

$box-shadow-small: 0px 4px 8px rgba(0, 0, 0, 0.06);
$box-shadow-medium-1: 0px 2px 10px rgba(0, 0, 0, 0.08);
$box-shadow-medium-2: 4px 4px 30px rgba(0, 0, 0, 0.12);
$box-shadow-large: 0 12px 30px rgba(0, 0, 0, 0.07);

// Z-index
$z-index-focused-panel-trigger: 1;
$z-index-button-content: 1;
$z-index-sidebar: 50;
$z-index-message: 50;
$z-index-dropdown-content: 75;
$z-index-dropdown: 75;
$z-index-modal-backdrop: 80;
$z-index-tooltip: 100;
$z-index-menu-toggle: 120;
$z-index-skip-navigation: 125;
$z-index-mobile-navigation: 10000;

// Size
// These are commonly used sizes across the application to create consistency among spacing throughout the application. If you need a space other than these, consider whether the exception is necessary.
$size-2: 0.2rem;
$size-4: 0.4rem;
$size-8: 0.8rem;
$size-12: 1.2rem;
$size-16: 1.6rem;
$size-24: 2.4rem;
$size-32: 3.2rem;
$size-40: 4rem;
$size-48: 4.8rem;
$size-72: 7.2rem;

//Reusable sizes for specific use cases
$size-gutter: 1.6rem;
$size-column: 9.6rem;
$size-container-margin-mobile: 2rem;
$size-container-margin-desktop: 3rem;

// Breakpoint
$breakpoint-mobile-small: 320;
$breakpoint-mobile: 480;
$breakpoint-tablet: 768;
$breakpoint-desktop: 1024;
$breakpoint-desktop-large: 1200;
$breakpoint-desktop-larger: 1800;

// Sidebar
$sidebar-width: 26rem;
//$header-height: 3rem;

// Container
$container-width: 132.8;

// Border-radius
$border-radius: 0.5rem;
$border-radius-large: 1rem;

// Typography
$type-040-font-size-desktop: 0.8rem;
$type-040-line-height-desktop: 1.2rem;
$type-040-font-size-mobile: 0.8rem;
$type-040-line-height-mobile: 1.2rem;

$type-060-font-size-desktop: 1rem;
$type-060-line-height-desktop: 1.6rem;
$type-060-font-size-mobile: 1rem;
$type-060-line-height-mobile: 1.6rem;

$type-080-font-size-desktop: 1.2rem;
$type-080-line-height-desktop: 1.6rem;
$type-080-font-size-mobile: 1.2rem;
$type-080-line-height-mobile: 1.6rem;

$type-100-font-size-desktop: 1.4rem;
$type-100-line-height-desktop: 2rem;
$type-100-font-size-mobile: 1.4rem;
$type-100-line-height-mobile: 2rem;

$type-200-font-size-desktop: 1.6rem;
$type-200-line-height-desktop: 2.4rem;
$type-200-font-size-mobile: 1.6rem;
$type-200-line-height-mobile: 2.4rem;

$type-300-font-size-desktop: 2rem;
$type-300-line-height-desktop: 2.8rem;
$type-300-font-size-mobile: 2rem;
$type-300-line-height-mobile: 2.8rem;

$type-400-font-size-desktop: 2.4rem;
$type-400-line-height-desktop: 3.2rem;
$type-400-font-size-mobile: 2.4rem;
$type-400-line-height-mobile: 3.2rem;

$type-500-font-size-desktop: 2.8rem;
$type-500-line-height-desktop: 4rem;
$type-500-font-size-mobile: 2.8rem;
$type-500-line-height-mobile: 4rem;

$type-600-font-size-desktop: 3.6rem;
$type-600-line-height-desktop: 4.4rem;
$type-600-font-size-mobile: 3.6rem;
$type-600-line-height-mobile: 4.4rem;

$type-700-font-size-desktop: 4rem;
$type-700-line-height-desktop: 4.8rem;
$type-700-font-size-mobile: 4rem;
$type-700-line-height-mobile: 4.8rem;

$type-800-font-size-desktop: 5.2rem;
$type-800-line-height-desktop: 6.4rem;
$type-800-font-size-mobile: 5.2rem;
$type-800-line-height-mobile: 6.4rem;

$type-block-title-font-size-desktop: 1.8rem;
$type-block-title-line-height-desktop: 2.4rem;
$type-block-title-font-size-mobile: 1.6rem;
$type-block-title-line-height-mobile: 2rem;

@import 'mixins/accessibility';
@import 'mixins/breakpoints';
@import 'mixins/spacing';
@import 'mixins/typography';
@import 'mixins/svg';
@import 'mixins/buttons.module';
@import 'mixins/gridWidth';
