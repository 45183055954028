@import '~scss/variables.scss';

.sidebar {
	display: flex;
	flex-direction: column;
}

.navSectionLabel {
	@include type-080;

	font-weight: $font-weight-bold;
	text-transform: uppercase;

	margin-top: 3.5rem;
	margin-bottom: 1.3rem;
	margin-left: 1.6rem;
	color: $color-gray-1;
}

.navSectionLabel:first-of-type {
	margin-top: 0;
}