@import '~scss/variables.scss';

.backdrop {
	background: rgba(0, 0, 0, 0.7);
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	overflow-y: scroll;
	z-index: $z-index-modal-backdrop;
	position: fixed;
}

.modal {
	display: flex;
	flex-direction: column;
	max-width: 80rem;
	margin: 6rem auto;

	@include mobile-up {
		margin: 10rem auto;
	}
}

.inner {
	@include padding-medium-horizontal;
	color: $color-gray-1;
	background-color: $color-white;
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: $color-white;
	@include padding-medium-horizontal;
	padding-top: $size-24;
	padding-bottom: $size-24;
	border-top-left-radius: $border-radius;
	border-top-right-radius: $border-radius;

	@include tablet-up {
		border-top-left-radius: $border-radius-large;
		border-top-right-radius: $border-radius-large;
	}

	.headerTitle {
		margin: 0;
		@include type-block-title;
		color: $color-black;
		@include tablet-up {
			color: $color-black;
		}
	}
}

.footer {
	background-color: $color-white;
	@include padding-medium-horizontal;
	@include padding-large-vertical;
	border-bottom-left-radius: $border-radius;
	border-bottom-right-radius: $border-radius;
	@include tablet-up {
		border-bottom-left-radius: $border-radius-large;
		border-bottom-right-radius: $border-radius-large;
		display: flex;
		justify-content: flex-start;
		flex-direction: row-reverse;
	}
}

.secondaryButton {
	margin-top: $size-16;
	@include tablet-up {
		margin-top: 0;
		margin-right: $size-8;
	}
}

.scrollContainer {
	overflow-y: scroll;
	margin-bottom: $size-48;
	height: 100vh;
}

.description {
	margin-top: 0;
}
