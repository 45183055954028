@mixin set-accessibility-focus {
	body:not(.user-is-tabbing) {
		@content;
	}
}

// Used within an element to detect when a user tabs.
@mixin only-tabbing-child {
	body.user-is-tabbing & {
		@content;
	}
}

@mixin tabbing {
	:global {
		.user-is-tabbing {
			:local {
				@content;
			}
		}
	}
}

@mixin screen-reader-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	word-wrap: normal !important;
}

.screenReaderText {
	@include screen-reader-text();
}
