@import '~scss/variables';

// Base Style
.badgeIcon {
	position: relative;
	display: flex;

	.badge {
		position: absolute;
		top: -0.4rem;
		right: -0.6rem;
	}
}
