@import '~scss/variables.scss';

html {
	box-sizing: border-box;
	font-size: 62.5%;
}
*,
*:before,
*:after {
	box-sizing: border-box;
}

body {
	min-height: 100vh;
}

@include set-accessibility-focus {
	*:focus:not([type='text']) {
		box-shadow: none !important;
		outline: none;
	}
}
